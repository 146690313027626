header {
	margin: 3% 0;
}
.logo img {
	aspect-ratio: 423/172;
	display: block;
	margin: 0 auto;
	max-width: 423px;
	max-height: 172px;
	width: 80%;
}
header h1 {
	margin: 35px 0 20px;
}
header h1 img {
	aspect-ratio: 431/47;
	display: block;
	margin: 0 auto;
	max-width: 431px;
	max-height: 47px;
	width: 85%;
}

@media only screen and (max-width: 600px) {
	header {
		padding: 20px 0;
	}
}
