* {
	margin: 0;
	padding: 0;
}
html {
	height: 100%;
}
body {
	height: 100%;
	background: #f5f5f5;
	font: 14px Helvetica, Arial, sans-serif;
	color: #000;
}
#root {
	width: 100%;
}
button {
	background: none;
  	border: 0;
	cursor: pointer;
	outline: none;
}
h1 {
	font-size: 50px;
}
ul, ol {
	list-style-type: none;
}
a {
	color: #000;
	text-decoration: none;
}
a span {
	text-decoration: underline;
}
a:hover span {
	text-decoration: none;
}
img {
	border: none;
}
