footer {
	margin: 11% 20px 25px;
}
.contacts {
	text-align: center;
	height: 23px;
}
.contacts img {
	display: block;
	margin: 0 auto;
	width: 208px;
	height: 23px;
}
.copyrights {
	margin-top: 7px;
	text-align: center;
}
.copyrights .line {
	display: inline-block;
	margin: 2px;
}
