section ul li {
  margin: 20px 0 20px 12%;
  width: 37%;
  float: left;
}
section ul li a {
  display: block;
}
section ul li img {
  width: 62px;
  height: 62px;
  vertical-align: middle;
}
section ul li span {
  margin-left: 15px;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
    section {
        width: 80%;
    }
    section ul li {
        margin: 30px 20px 30px 50px;
        width: auto;
        float: none;
    }
}
