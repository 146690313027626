section {
	margin: 10px 0;
}
.sectionTitle {
	margin: 0 0 35px 40px;
	font-size: 25px;
}
section ul {
	overflow: hidden;
}

@media only screen and (max-width: 600px) {
	.sectionTitle {
		margin: 0 0 10px 20px;
		font-size: 25px;
	}
}
