.page {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 780px;
    min-height: 100vh;
}
.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
.flexible1 {
    display: flex;
    flex-grow: 1;
}
.flexible3 {
    display: flex;
    flex-grow: 3;
}

@supports (-webkit-touch-callout: none) {
    .page {
        min-height: -webkit-fill-available;
    }
}